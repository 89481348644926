* {
  box-sizing: border-box;
  color: white;
}

html,
body {
  background: linear-gradient(#d9e7ff, #cfe2ff);
  background-repeat: no-repeat;
  height: 100vh;
}
